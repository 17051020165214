AFRAME.registerComponent("model-texture", {
  schema: { default: "summer" },
  init: function () {
    this.el.addEventListener("model-loaded", this.update.bind(this));
  },
  update: function () {
    var mesh = this.el.getObject3D("mesh");
    var data = this.data;
    var loader = new THREE.TextureLoader();
    if (!mesh) {
      return;
    }
    mesh.traverse(function (node) {
      if (node.isMesh) {
        var filename = "map_texture_summer.jpg";
        if (data == "winter") {
          filename = "map_texture_snow.jpg";
        } else if (data == "transition") {
          filename = "map_texture_springautumn.jpg";
        }
        if (node.material.name == "terrain_material") {
          node.material.map = loader.load(filename);
          node.material.map.flipY = false;
          node.material.needsUpdate = true;
        }
      }
    });
  },
});
